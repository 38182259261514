<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import Plans from "@/components/widgets/loading-plans";

export default {
  locales: {
    pt: {
      Upgrade: "Upgrade",
      "Choose your upgrade package": "Escolha seu pacote de upgrade",
      "You choose the upgrade to": "Você escolheu o upgrade para",
      "Do you want to confirm?": "Deseja confirmar?",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      Upgrade: "Upgrade",
      "Choose your upgrade package": "Elige tu paquete de actualización",
      "You choose the upgrade to": "Usted elige la actualización a",
      "Do you want to confirm?": "¿Quieres confirmar?",
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: { Layout, Plans },
  data() {
    return {
      upgrade: {
        id: "",
        name: "",
        value: "",
      },
      upgrades: {
        loading: false,
        errored: false,
        empty: false,
        list: [],
      },
      buy: {
        loading: false,
        alert: { type: "", message: "" },
      },
    };
  },
  methods: {
    getList() {
      this.upgrades.loading = true;

      api
        .get("user/upgrade")
        .then((response) => {
          this.upgrades.loading = false;
          if (response.data.status == "success") {
            this.upgrades.list = response.data.list;
          } else {
            this.upgrades.empty = true;
          }
        })
        .catch((error) => {
          this.upgrades.errored = error;
        });
    },
    setUpgrade(upgrade) {
      if (upgrade) {
        this.upgrade.id = upgrade;
        this.upgrade.name = this.upgrades.list[upgrade].name;
        this.upgrade.value = this.upgrades.list[upgrade].value;
      } else {
        this.upgrade.id = "";
        this.upgrade.name = "";
        this.upgrade.value = "";
      }
    },
    buyUpgrade() {
      if (this.upgrade.id) {
        this.buy.loading = true;

        api
          .post("user/upgrade", {
            id: this.upgrade.id,
          })
          .then((response) => {
            if (response.data.status == "success") {
              this.$router.push("/store/order/" + response.data.order.id);
            } else {
              this.buy.loading = false;
              this.buy.alert.type = "alert-danger";
              this.buy.alert.message = response.data.message;
            }
          });
      }
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Upgrade") }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-7">
        <div class="card">
          <div class="card-body">
            <div v-if="upgrades.errored">
              {{
                t(
                  "We apologize, we are unable to retrieve information at this time. Please try again later."
                )
              }}
            </div>
            <div v-else-if="upgrades.empty">{{ t("No records found.") }}</div>
            <div v-else>
              <div v-if="!upgrade.id">
                <div class="text-center mb-4">
                  {{ t("Choose your upgrade package") }}
                </div>
                <div v-if="upgrades.loading">
                  <Plans class="mb-2"></Plans>
                  <Plans class="mb-2"></Plans>
                  <Plans class="mb-2"></Plans>
                </div>
                <button
                  v-else
                  v-for="(upgrade, key) of upgrades.list"
                  :key="key"
                  v-on:click="setUpgrade(key)"
                  class="btn btn-default btn-block text-white font-size-18 text-left d-flex justify-content-between"
                >
                  <span>{{ upgrade.name }}</span>
                  <span>+ {{ upgrade.value }}</span>
                  <span>></span>
                </button>
              </div>
              <div v-else>
                <div
                  v-if="buy.alert.message"
                  :class="'alert ' + buy.alert.type"
                >
                  {{ buy.alert.message }}
                </div>
                <div v-if="!buy.loading">
                  <div
                    v-on:click="setUpgrade()"
                    class="btn btn-default btn-block text-white font-size-18 text-left d-flex justify-content-between mb-3"
                  >
                    <span>{{ upgrade.name }}</span>
                    <span>+ {{ upgrade.value }}</span>
                    <span
                      ><i
                        class="bx bx-x font-size-20 align-middle m-0 mb-1 p-0"
                      ></i
                    ></span>
                  </div>
                  <div class="text-center">
                    {{ t("You choose the upgrade to") }}:
                    <h3>{{ upgrade.name }}</h3>
                    <h5 class="mt-4">{{ t("Do you want to confirm?") }}</h5>
                    <button
                      v-on:click="buyUpgrade()"
                      class="btn btn-default mb-3"
                    >
                      Yes
                    </button>
                    <button
                      v-on:click="setUpgrade()"
                      class="btn btn-default mb-3 ml-2"
                    >
                      No
                    </button>
                  </div>
                </div>
                <div v-else>
                  <div
                    class="btn btn-default btn-block text-white font-size-18 text-left d-flex justify-content-between mb-3"
                  >
                    <span>{{ upgrade.name }}</span>
                    <span>+ {{ upgrade.value }}</span>
                    <span
                      ><i
                        class="bx bx-x font-size-20 align-middle m-0 mb-1 p-0"
                      ></i
                    ></span>
                  </div>
                  <div class="text-center">
                    {{ t("You choose the upgrade to") }}:
                    <h3>{{ upgrade.name }}</h3>
                    <h5 class="mt-4">{{ t("Do you want to confirm?") }}</h5>
                    <button class="btn btn-default mb-3">Yes</button>
                    <button class="btn btn-default mb-3 ml-2">No</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
